import React from 'react';
import './App.css';

export const App = () => {
  return (
    <div>
      <ul>
        <li className="listWrap">
          <div>j-tamasホームページ（リンク先：</div>
          <a href='https://j-tamas.com/' target='_blank' rel='noopener noreferrer'>https://j-tamas.com/</a>
          <div>）</div>
        </li>
        <li className="listWrap">
          <div>OPTEMOのヘルプページ（リンク先：</div>
          <a href='https://faq.j-tamas.com/' target='_blank' rel='noopener noreferrer'>https://faq.j-tamas.com/</a>
          <div>）</div>
        </li>
        <li className="listWrap">
          <div>OPTEMOのログインページ（リンク先：</div>
          <a href='https://dashboard.optemo.jp/login' target='_blank' rel='noopener noreferrer'>https://dashboard.optemo.jp/login</a>
          <div>）</div>
        </li>
      </ul>
    </div>
  );
}
